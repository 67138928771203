import './list.scss';
import dataApi from '@/api/product/product.js';
import workbench from '@/api/manage/workbench';
/*
 * 报名查询
 *  */
export default {
  name: 'manage-query',
  components: {},
  data() {
    return {
      page: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      form: {},
      queryOp: {
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        submitText: '搜索',
        emptyText: '清空',
        menuPosition: 'center',
        tabs: false,
        column: [
          {
            label: '所属活动',
            prop: 'product_id',
            type: 'table',
            children: {
              border: true,
              searchMenuSpan: 8,
              column: [
                {
                  type: 'input',
                  label: '活动名称',
                  display: true,
                  prop: 'name',
                  required: true,
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  type: 'tree',
                  label: '所属分类',
                  search: true,
                  props: {
                    label: 'label',
                    value: 'value'
                  },
                  dicUrl: `${this.$baseUrl}general/get_product_category`,
                  dicQuery: {},
                  dicMethod: 'post',
                  span: 12,
                  display: true,
                  prop: 'product_category_id',
                  required: true,
                  rules: [
                    {
                      required: true,
                      message: '所属分类必须填写'
                    }
                  ]
                }
              ]
            },
            formatter: (row) => {
              if (!row.name) return '';
              this.getProductCourse(row);
              return `${row.name}`;
            },
            onLoad: ({ value, page, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                return;
              }
              // 请求列表数据
              dataApi.getList
                .r({ page: page.currentPage, pageSize: page.pageSize, ...data })
                .then((res) => {
                  // 渲染数据
                  callback({
                    total: res.data.total,
                    data: [...res.data.list]
                  });
                });
            },
            props: {
              label: 'name',
              value: 'id'
            }
          },
          {
            label: '所属班级',
            prop: 'product_course_id',
            type: 'select',
            formslot: true
          }
        ]
      },
      tableData: [],
      tbleOption: {
        total: 20,
        align: 'center',
        addBtn: false,
        cellBtn: false,
        cancelBtn: false,
        ...this.$store.getters.tableConfig,
        menu: false,
        selection: false,
        column: [
          {
            label: '所属活动',
            prop: 'product_name'
          },
          {
            label: '所属班级',
            prop: 'course_text'
          },
          {
            label: '会员名称',
            prop: 'member_name'
          },
          {
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          }
        ]
      },
      searchData: {},
      product: [],
      course: [], // 所属班级
      query: {}
    };
  },
  computed: {},
  mounted() {
    this.getProduct();
  },
  filters: {},
  methods: {
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      console.log(params);
      // if (this.activitySearchFo.table) {
      //   params.product_id = this.activitySearchFo.table;
      // }
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    getProduct() {
      workbench.getProduct.r().then((res) => {
        this.product = res.data;
      });
    },
    getProductCourse(row) {
      console.log(row);
      this.course = [];
      dataApi.getProductCourse.r({ product_id: row.id }).then((res) => {
        console.log('rw', res);
        this.course.push(...res.data);
      });
    },
    queryProductSignin() {},
    querySignin(e, field) {
      // 查询指定活动当前会员是否已经购买
      if (
        this.form.member === '' ||
        this.form.product === '' ||
        this.form.datetime === ''
      ) {
        return false;
      }
      this.isSubmit = false;
      const queryData = {
        member_id: field === 'member' ? e : this.form.member.value,
        product_id: field === 'product' ? e : this.form.product.value,
        datetime: field === 'datetime' ? e : this.form.datetime
      };
      console.log('queryData', queryData);
      dataApi.queryMemberProductSign.r(queryData).then((res) => {
        this.form.remark = res.data;
        this.isSubmit = true;
      });
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getSignUpList.r(postData).then((res) => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
